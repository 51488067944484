import React, { useEffect, useRef, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { Button, ConfigProvider, Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { PlaylistsAPI } from 'api/playlists';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { NEW_PLAYLIST_TEXT } from 'types/constants';
import { generateUUID } from 'types/crypto';

import {
  AC_SetPlayerHotkeysAllowedAction,
  AC_setPlayerMode,
} from '../../actions/player.acitons';
import {
  openPlaylistActionV2,
  updatePlaylistAction,
} from '../../actions/playlistV2.async.actions';
import { ReactComponent as CheckboxFilled } from '../../assets/img/icons/faCheckboxFilled.svg';
import { ReactComponent as CloseCross } from '../../assets/img/icons/faCloseCross.svg';
import { ReactComponent as PlaylistIcon } from '../../assets/img/icons/faListIcon.svg';
import { ReactComponent as PlaylistIconTree } from '../../assets/img/tree/faPlaylist.svg';
import PlaylistContext from '../../components/contexts/PlaylistContext';
import { playlistReducerV2 } from '../../reducers/playlist.reducer';
import { useAppDispatch } from '../../store';

const TitleWithTooltip = ({ folder }: { folder: any }) => {
  const ref = useRef<any>(null);
  const isOverflowing =
    (ref?.current?.scrollWidth || 0) > (ref?.current?.clientWidth || 0);
  return (
    <Tooltip
      color="var(--tooltip-75)"
      overlayStyle={{
        border:
          '1px solid var(--character-disabled-amp-placeholder-25, rgba(255, 255, 255, 0.30))',
        borderRadius: '8px',
      }}
      title={isOverflowing ? folder.name : ''}
      placement="bottom"
      arrow={false}
    >
      <div ref={ref} className={'flex-row folder-name'} style={{ flex: 1 }}>
        {folder.name}
      </div>
    </Tooltip>
  );
};

const PlaylistElement = ({
  playlist,
  // folder,
  root = false,
  movePlayToFolderCallback,
  shareEpisodesCallable,
  deletePlaylistCallback,
}: {
  playlist: any;
  folder?: any;
  root?: boolean;
  movePlayToFolderCallback: any;
  shareEpisodesCallable: any;
  deletePlaylistCallback: any;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: playlist.id });
  const getStyle = (transition: any, transform: any) => {
    // if (expanded.includes(folder?.parent)) {
    //     style = {...style, ...mountedStyle}
    // } else {
    //     style = {...style, ...unmountedStyle}
    // }
    return {
      position: 'relative',
      display: 'flex',
      transition,
      transform: CSS.Transform.toString(transform),
    };
  };
  const dispatch = useAppDispatch();
  const { AC_setOpenedPlaylistId } = playlistReducerV2.actions;
  const openPlaylist = () => {
    dispatch(openPlaylistActionV2(playlist.elId));
    dispatch(AC_setOpenedPlaylistId(playlist.elId));
    dispatch(AC_setPlayerMode('playlist_view'));
  };
  const [renameMode, setRenameMode] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState<string>(playlist.name);
  const [inputStatus, setInputStatus] = React.useState<any>('');
  const { showNotification } = notificationsReducer.actions;
  const [t] = useTranslation();
  const [renamePlaylistPlaceholder, setRenamePlaylistPlaceholder] = useState(
    t(NEW_PLAYLIST_TEXT),
  );
  const renamePlaylistHandler = async () => {
    if (newPlaylistName || renamePlaylistPlaceholder) {
      PlaylistsAPI.updatePlaylist(
        playlist.elId,
        newPlaylistName || renamePlaylistPlaceholder,
      )
        .then((result: any) => {
          if (result.status === 'error') {
            setInputStatus('error');
            dispatch(
              showNotification({
                notificationParameters: [
                  {
                    id: generateUUID(),
                    text: t('Playlist with this name already exists'),
                    type: 'error',
                  },
                ],
              }),
            );
          } else {
            dispatch(updatePlaylistAction());
            setRenameMode(false);
            setNewPlaylistName('');
            dispatch(AC_SetPlayerHotkeysAllowedAction(true));
          }
        })
        .catch(() => {
          setInputStatus('error');
        });
    } else {
      setInputStatus('error');
    }
  };
  const inputRef = useRef(null);
  useEffect(() => {
    if (
      newPlaylistName &&
      inputRef.current &&
      newPlaylistName === playlist.name
    ) {
      //@ts-ignore
      inputRef.current!.focus({
        cursor: 'all',
      });
    }
  }, [renameMode, newPlaylistName]);
  const updatePlaylistNamePlaceholder = () => {
    const language = localStorage.getItem('i18nextLng') || 'en';
    PlaylistsAPI.getSuggestedPlaylistName(language).then((response: any) => {
      setRenamePlaylistPlaceholder(response.data.name);
    });
  };
  const setRenameModeHandler = () => {
    setRenameMode(true);
    updatePlaylistNamePlaceholder();
    setNewPlaylistName(playlist.name);
  };
  return (
    <div
      ref={setNodeRef}
      id={playlist.id}
      //@ts-ignore
      style={getStyle(transition, transform)}
      // onAnimationEnd={() => {
      //     if (!expanded.includes(playlist?.folder?.id)) setShowDiv(false);
      //  }}

      {...attributes}
      {...listeners}
    >
      {!root && (
        <>
          <div
            style={{
              width: `${18 * playlist.folder_level}px`,
              height: '24px',
              flexShrink: 0,
              position: 'relative',
              overflow: 'visible',
            }}
          ></div>

          <div style={{ width: 18, marginLeft: -6 }}></div>
          <PlaylistIconTree
            style={{ width: '34px', left: 10, height: '24px' }}
          />
        </>
      )}
      <div
        className="playlist-folder-element enabledClickable"
        style={{ position: 'relative', flex: 1 }}
      >
        {!renameMode && (
          <div
            style={{ flex: 1, overflow: 'hidden' }}
            className="flex-row ai-c elipsis-text"
            onClick={openPlaylist}
          >
            <PlaylistIcon width={24} />
            <TitleWithTooltip folder={playlist} />
          </div>
        )}
        {renameMode && (
          <ConfigProvider
            theme={{
              token: {
                colorTextPlaceholder: 'var(--colorTextPlaceholder)',
                fontFamily: 'SFProTextRegular',
                fontSize: 14,
                colorBorder: 'var(--colorPrimaryHover)',
              },
            }}
          >
            <Input
              status={inputStatus}
              ref={inputRef}
              className="new-folder-input"
              type="text"
              name="newFolderName"
              onChange={(event: any) => {
                setInputStatus('');
                setNewPlaylistName(event.target.value);
              }}
              autoFocus={true}
              maxLength={100}
              placeholder={renamePlaylistPlaceholder}
              defaultValue={newPlaylistName}
              style={{
                background: 'var(--colorBgContainer)',
                color: 'var(--colorText)',
              }}
            ></Input>
            <Button
              style={{
                border: '1px solid var(--colorIcon)',
                background: 'transparent',
                height: 24,
                width: 32,
              }}
              onClick={() => {
                setRenameMode(false);
                setInputStatus('');
                setNewPlaylistName('');
                dispatch(AC_SetPlayerHotkeysAllowedAction(true));
              }}
              icon={<CloseCross />}
            />
            <Button
              style={{
                height: 24,
                width: 32,
                border: '1px solid var(--colorPrimaryBase)',
                background: 'rgba(22, 104, 220, 0.4)',
              }}
              onClick={renamePlaylistHandler}
              icon={<CheckboxFilled />}
              type="default"
            />
          </ConfigProvider>
        )}
        <div className="flex-row ai-c j-ce">
          <div className="elements-count">
            {playlist.episodes_count > 0 ? playlist.episodes_count : ''}
          </div>
          <PlaylistContext
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            downloadCallback={() => {}}
            deleteCallback={deletePlaylistCallback}
            shareCallback={shareEpisodesCallable}
            playlist={playlist}
            openRenameMode={setRenameModeHandler}
            movePlayToFolderCallback={movePlayToFolderCallback}
          />
        </div>
      </div>
    </div>
  );
};
export default PlaylistElement;
