import React, { useEffect, useMemo, useState } from 'react';
import './index.css';

import { ConfigProvider, Input, Tooltip, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Context from 'react-redux/es/components/Context';

import {
  getPlaylistsAction,
  AppendNewPlaylist,
} from '../../../actions/player.acitons';
import { EventsAPI } from '../../../api/events';
import { PlaylistsAPI } from '../../../api/playlists';
import { ReactComponent as BasketIcon } from '../../../assets/img/icons/faBasketIcon.svg';
import { ReactComponent as CloseCrossIcon } from '../../../assets/img/icons/faCloseCross.svg';
import { ReactComponent as CopyIcon } from '../../../assets/img/icons/faCopyIcon.svg';
import { AppStateType } from '../../../reducers';
import { useAppDispatch } from '../../../store';

const PlaylistShareControl = ({
  closeCallable,
  sharedPlaylistId,
  // shareLink,
  linkData,
}: {
  closeCallable: any;
  sharedPlaylistId: string;
  shareLink?: any;
  linkData: any;
}) => {
  const { playlistsAndFolders } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  const { openedPlaylist } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  const [playlistId, setPlaylistId] = useState<string | null>(sharedPlaylistId);
  const [suggestedName, setSuggestedName] = useState<string>('');
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const getName = async () => {
    const language = localStorage.getItem('i18nextLng') || 'en';
    const response = await PlaylistsAPI.getSuggestedPlaylistName(language);
    setSuggestedName(response?.data.name);
  };

  const createLink = async (playlist: any, playlistId: string) => {
    const createdLink = await PlaylistsAPI.createShareLink(playlistId);
    if (createdLink.status === 200) {
      // console.log('b');
      const host = window.location.host;
      copyToClipboard(`${host}${createdLink.data.share_link}`, false);
      if (sharedPlaylistId === 'create') {
        const createdPlaylist = {
          ...playlist,
          share_link: createdLink.data.share_link,
        };
        dispatch(AppendNewPlaylist(createdPlaylist));
      }
      // console.log('c');
      return {
        playlistId: playlistId,
        link: `${host}${createdLink.data.share_link}`,
      };
    }
    // console.log('a');
    return { playlistId: playlistId, link: createdLink };
  };

  const createdOrCopyExistingLink = async () => {
    let playlist;
    let playlistTempId = '';
    let link = null;
    if (playlistId && openedPlaylist?.share_link) {
      const host = window.location.host;
      const shownLink = openedPlaylist.share_link.includes('http')
        ? openedPlaylist.share_link
        : `${host}${openedPlaylist.share_link}`;
      return { playlistId: playlistId, link: shownLink };
    }
    if (sharedPlaylistId === 'create') {
      let name = suggestedName;
      if (!name) {
        const language = localStorage.getItem('i18nextLng') || 'en';
        const response = await PlaylistsAPI.getSuggestedPlaylistName(language);
        name = response.data.name;
        setSuggestedName(name);
      }
      const response = await PlaylistsAPI.addPlaylist(name);
      playlist = response.data;
      const flatEpisodes = Object.values(linkData.episodes)
        .map((el: any) => el)
        .flat();
      setPlaylistId(playlist.data.id);
      playlistTempId = playlist.data.id;
      await PlaylistsAPI.addToPlaylists(flatEpisodes, [playlist.data.id]);
    } else {
      if (openedPlaylist && openedPlaylist.id === sharedPlaylistId) {
        playlistTempId = openedPlaylist.id;
        playlist = openedPlaylist;
        link = playlist.share_link;
      } else {
        // console.log('here', sharedPlaylistId);
        playlist = playlistsAndFolders[0]?.playlists.filter(
          (el: any) => el.elId === sharedPlaylistId,
        )[0];
        if (playlist) {
          playlistTempId = playlist?.elId;
          link = playlist.share_link;
        } else {
          playlistTempId = sharedPlaylistId;
          link = null;
        }
      }
    }
    setPlaylistId(playlistTempId);
    // console.log('playlistTempId', playlistTempId);
    if (
      playlistTempId &&
      (openedPlaylist?.share_link === null ||
        openedPlaylist === null ||
        link === null)
    ) {
      return await createLink(playlist, playlistTempId);
    }
    return { playlistId: playlistTempId, link: link };
  };
  const deleteLink = async () => {
    if (playlistId) {
      await EventsAPI.deletePlaylistLink(playlistId).then(() => {
        closeCallable(null);
      });
      setPlaylistId('');
      dispatch(getPlaylistsAction());
    }
  };
  const [api, contextHolder] = notification.useNotification({ stack: false });
  const openNotification = (
    showTooltip: boolean,
    link: string,
    key: string,
  ) => {
    if (key !== undefined) {
      try {
        // console.log('key', key);
        api.info({
          message: '',
          key: key,
          duration: 0,
          className: 'link-create-notification',
          description: (
            <Tooltip
              arrow={false}
              title={t('Link was copied')}
              open={showTooltip}
              placement="top"
              style={{ marginBottom: 20 }}
              zIndex={2060}
              fresh
            >
              <div
                className="flex-row ai-c f-ga-8"
                style={{
                  flex: 1,
                  marginRight: 0,
                  marginInlineStart: 0,
                  justifyContent: 'space-between',
                }}
              >
                <BasketIcon
                  style={{ width: 32 }}
                  className="enabledClickable"
                  onClick={deleteLink}
                />
                <ConfigProvider>
                  <Input
                    readOnly
                    defaultValue={link}
                    suffix={
                      <CopyIcon
                        className="enabledClickable"
                        onClick={() => copyToClipboard(link, true)}
                      />
                    }
                  />
                  <CloseCrossIcon
                    onClick={() => closeCallable(null)}
                    style={{ width: 24 }}
                    className="white-icon enabledClickable"
                  />
                </ConfigProvider>
              </div>
            </Tooltip>
          ),
          placement: 'bottomRight',
          icon: <></>,
          style: {
            padding: 12,
            background: 'var(--Primary-7)',
            borderRadius: '8px',
            marginInlineStart: 0,
          },
          closeIcon: null,
          onClose: () => closeCallable(null),
        });
        // console.log('ss', key);
      } catch (e) {
        console.log(e);
      }
    }
  };
  useEffect(() => {
    if (sharedPlaylistId) {
      // console.log('aaa', sharedPlaylistId);
      getName()
        .then(() => {
          createdOrCopyExistingLink()
            .then(({ playlistId, link }: any) => {
              // console.log('x');
              openNotification(false, link, playlistId);
              // console.log('y');
              setTimeout(() => {
                openNotification(true, link, playlistId);
              }, 500);
              // console.log('r');
              setTimeout(() => {
                openNotification(false, link, playlistId);
              }, 3000);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);
  const copyToClipboard = (link: any, showT: boolean) => {
    try {
      navigator?.clipboard.writeText(link);
      if (playlistId && showT) {
        openNotification(true, link, playlistId);
        setTimeout(() => {
          openNotification(true, link, playlistId);
        }, 500);
        setTimeout(() => {
          openNotification(false, link, playlistId);
        }, 3000);
      }
    } catch (e) {
      const elem = document?.createElement('textarea');
      elem.value = link;
      document?.body.appendChild(elem);
      elem.select();
      document?.execCommand('copy');
      document?.body.removeChild(elem);
    }
  };
  const contextValue = useMemo(() => <></>, []);
  return (
    <Context.Provider
      //@ts-ignore
      value={contextValue}
    >
      {contextHolder}
    </Context.Provider>
  );
};
export default PlaylistShareControl;
